<template>
  <a-modal
    title="分配任务"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="deployMissionApi"
    @cancel="cancelForm"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="任务名称"
        >
          <a-input
            v-model="missionInfo.title"
            :disabled="true"
          />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="备注"
        >
          <a-textarea :rows="5" :disabled="true" v-model="missionInfo.desc" placeholder="备注" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="执行人"
          :validateStatus="checkParams.workerIdStatus"
          :help="checkParams.workerIdStatusMsg"
        >
          <a-select
            style="width:70%"
            placeholder="请选择执行人"
            v-model="workerId"
            @change="changeAdminUser"
          >
            <a-select-option :key="0" :value="0">请选择执行人</a-select-option>
            <a-select-option v-for="item in adminUser" :key="item.id" :value="item.id">
              {{ item.realName }}({{ item.telephone }})
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import { deployMission, missionInfo } from '@/api/mission'
import { searchAdminUser } from '@/api/user'

export default {
  name: 'MissionDistribute',
  components: {
  },
  props: {
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'AddMissionDistribute' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      confirmLoading: false,
      missionInfo: {
        workerId: ''
      },
      userMission: {},
      workerId: '',
      adminUser: [],
      tabindex: 1,
      checkParams: {
        workerIdStatus: 'success',
        workerIdStatusMsg: null
      }
    }
  },
  created () {
  },
  methods: {
    ininData (data, tabindex = 1) {
      this.checkParams.workerIdStatus = 'success'
      this.checkParams.workerIdStatusMsg = null
      this.searchAdminUserApi()
      this.allocation(data)
      this.tabindex = tabindex
      this.visible = true
    },
    allocation (data) {
      const _this = this
      _this.loading = true
      missionInfo({ keyId: data.keyId }).then((res) => {
        if (res.errorCode === 0) {
          _this.missionInfo = res.result.mission
          _this.userMission = res.result.userMission
          _this.missionInfo.workerId = ''
          _this.missionInfo.userMissionKeyId = 0
          if (_this.userMission.workerId !== '') {
            _this.workerId = _this.userMission.workerId
            _this.missionInfo.workerId = _this.userMission.workerId
          }
          if (_this.userMission.keyId) {
            _this.missionInfo.userMissionKeyId = _this.userMission.keyId
          }
          console.log(_this.missionInfo)
          _this.loading = false
          _this.distributeVisible = true
        } else {
          _this.loading = false
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.loading = false
        console.log(err)
      })
    },
    cancelForm () {
      this.visible = false
    },
    deployMissionApi () {
      const _this = this
      if (_this.missionInfo.workerId !== 0) {
        _this.checkParams.workerIdStatus = 'success'
        _this.checkParams.workerIdStatusMsg = null
      } else {
        _this.checkParams.workerIdStatus = 'error'
        _this.checkParams.workerIdStatusMsg = '请选择执行人'
        return false
      }
      _this.confirmLoading = true
      deployMission(_this.missionInfo).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('分配成功')
          _this.visible = false
          this.$emit('ok', this.tabindex)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.confirmLoading = false
      }).catch((err) => {
        _this.confirmLoading = false
        console.log(err)
      })
    },
    changeAdminUser (e) {
      console.log(e)
      this.missionInfo.workerId = e
      if (this.missionInfo.workerId !== 0) {
        this.checkParams.workerIdStatus = 'success'
        this.checkParams.workerIdStatusMsg = null
      } else {
        this.checkParams.workerIdStatus = 'error'
        this.checkParams.workerIdStatusMsg = '请选择执行人'
      }
      console.log(this.missionInfo)
    },
    searchAdminUserApi () {
      const _this = this
      searchAdminUser({ searchName: '' }).then((res) => {
        if (res.errorCode === 0) {
          _this.adminUser = res.result.data
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
